import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Capacitor } from '@capacitor/core';
import { AFConstants, AFInit, AppsFlyer } from 'appsflyer-capacitor-plugin';
import { Router } from '@angular/router';
import { AnalyticsService } from '../analytics/analytics.service';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

@Injectable({ providedIn: 'root' })
export class DeepLinksService {

  /** Dependencies */
  private router = inject(Router);
  private analyticsService = inject(AnalyticsService);

  /**
   * Init analytics
   */
  public async initialize() {
    if (!Capacitor.isNativePlatform()) { return; }

    this.setDeepLinkListeners();

    const { appsflyer } = environment;
    const appId = Capacitor.getPlatform() === 'ios' ? appsflyer.appIdIOS : appsflyer.appIdAndroid;
    const options: AFInit = {
      appID: appId,
      devKey: appsflyer.devKey,
      isDebug: true,
      waitForATTUserAuthorization: 100,
      registerOnDeepLink: true,
      minTimeBetweenSessions: 5,
      deepLinkTimeout: 100,
    };

    AppTrackingTransparency.requestPermission();
    await AppsFlyer.initSDK(options);
  }

  /**
   * Set deep link listener
   */
  private setDeepLinkListeners() {
    AppsFlyer.addListener(AFConstants.UDL_CALLBACK, ({ deepLink, status, error }) => {
      // TODO: Remove when finish the deep links test
      console.log('UDL_CALLBACK - deeplink:', deepLink);
      console.log('UDL_CALLBACK - status:', status);
      console.log('UDL_CALLBACK - error:', error);
      if (status === 'FOUND') {
        this.onDeepLinkFound(deepLink);
      } else {
        console.error('Error on UDL_CALLBACK listener:', { status, error});
      }
    });
  }

  /**
   * On deep link found
   */
  private onDeepLinkFound(deepLink: Record<string, string>) {
    const { utm_source, custom_path, media_source, campaign } = deepLink;

    // Set utm params
    const searchParams = new URLSearchParams({ utm_source });
    this.analyticsService.setUtmParams(searchParams);

    // Navigate to custom path
    if (custom_path) {
      this.router.navigateByUrl(custom_path);
    }

    // TODO: Remove when finish the deep links test
    if (utm_source === 'utmtest') {
      const resetPasswordElement = document.querySelector('.reset-password') as HTMLElement;
      if (resetPasswordElement?.style) {
        resetPasswordElement.style.color = 'black';
      }
    }
    if (media_source === 'untracked') {
      const resetPasswordElement = document.querySelector('.reset-password') as HTMLElement;
      if (resetPasswordElement?.style) {
        resetPasswordElement.style.marginRight = '24px';
      }
    }
    if (campaign === 'generic') {
      const resetPasswordElement = document.querySelector('.reset-password') as HTMLElement;
      if (resetPasswordElement?.style) {
        resetPasswordElement.style.fontSize = '24px';
      }
    }
  }

}