import { ApplicationConfig, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding, withPreloading } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { CustomPreloadingStrategyService } from '@core/services/custom-preloading-strategy/custom-preloading-strategy.service';
import { APP_ROUTES } from './app.routes';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { provideNgxStripe } from 'ngx-stripe';
import { environment } from '@env/environment';
import { NgcCookieConsentService, provideNgcCookieConsent } from 'ngx-cookieconsent';
import { COOKIES_CONFIG } from '@helpers/common.helpers';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { requestInterceptor } from '@core/interceptors/request.interceptor';
import { NgrxModule } from '@store/store.module';
import { cacheInterceptor } from '@core/interceptors/cache.interceptor';
import { appInitializer } from './app.initializer';
import { Store } from '@ngrx/store';
import { AdsService } from '@core/services/plugin-services/ads/ads.service';
import { StorageService } from '@core/services/storage/storage.service';
import { provideTranslateService, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { StoreState } from '@store/state/store.state';
import { WINDOW } from 'custom-window';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { DeepLinksService } from '@core/services/deep-links/deep-links.service';

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    provideIonicAngular(),
    provideAnimationsAsync(),
    provideRouter(
      APP_ROUTES,
      withPreloading(CustomPreloadingStrategyService),
      withComponentInputBinding()
    ),
    provideHttpClient(withInterceptors([
      cacheInterceptor,
      requestInterceptor,
    ])),
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: () => new TranslateHttpLoader(inject(HttpClient)),
      },
      isolate: false
    }),
    provideNgcCookieConsent(COOKIES_CONFIG),
    provideNgxStripe(environment.stripeKey),
    importProvidersFrom(
      CoreModule,
      NgrxModule,
    ),
    provideAppInitializer(() => {
      const initializerFn = appInitializer(
        inject(Store<StoreState>),
        inject(TranslateService),
        inject(StorageService),
        inject(NgcCookieConsentService),
        inject(AdsService),
        inject(AnalyticsService),
        inject(DeepLinksService),
        inject(WINDOW),
      );
      return initializerFn();
    }),
  ],
};
